import { useState, useContext } from "react";
import styles from "./InputApiKey.module.css";
import { SessionDispatchContext } from "../../context/sessionContext";

export const InputApiKey = () => {
    const [question, setQuestion] = useState<string>("");
    const { setApiKey } = useContext(SessionDispatchContext);

    const onChangeInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = ev.target.value;

        if (!newValue) {
            setQuestion("");
            setApiKey("")
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
            setApiKey(newValue)
        }
    };

    return (
        <div>
            <input
                style={{
                    height: 35,
                    padding: 10, 
                    borderRadius: 8
                }}
                className={styles.input}
                placeholder="Digite sua API key"
                value={question}
                onChange={onChangeInput}
            />
        </div>
    );
};
