import { ReactNode, createContext, useState } from "react";

interface SessionProviderProps {
  children: ReactNode;
}

const SessionContext = createContext<{ sessionId?: string; apiKey?: string }>({});
const SessionDispatchContext = createContext<any>(undefined);

function SessionProvider({ children }: SessionProviderProps) {
  const [sessionId, setSessionId] = useState<string>("");
  const [apiKey, setApiKey] = useState<string>("");

  return (
    <SessionContext.Provider value={{ sessionId, apiKey }}>
      <SessionDispatchContext.Provider value={{ setSessionId, setApiKey }}>
        {children}
      </SessionDispatchContext.Provider>
    </SessionContext.Provider>
  );
}

export { SessionProvider, SessionContext, SessionDispatchContext };
