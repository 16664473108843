import { useRef, useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton } from "@fluentui/react";
import styles from "./Chat.module.css";
import { chatApi, ChatResponse } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { ClearChatButton } from "../../components/ClearChatButton";
import { SessionContext, SessionDispatchContext } from "../../context/sessionContext";

const Chat = () => {
  const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
  const [promptTemplate, setPromptTemplate] = useState<string>("");
  const [retrieveCount, setRetrieveCount] = useState<number>(3);
  const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
  const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
  const [excludeCategory, setExcludeCategory] = useState<string>("");
  const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);

  const lastQuestionRef = useRef<string>("");
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  const [activeCitation, setActiveCitation] = useState<string>();
  const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

  const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
  const [answers, setAnswers] = useState<[user: string, response: ChatResponse][]>([]);

  const { setSessionId } = useContext(SessionDispatchContext);
  const { sessionId, apiKey } = useContext(SessionContext);

  const makeApiRequest = async (question: string) => {
    lastQuestionRef.current = question;

    error && setError(undefined);
    setIsLoading(true);
    setActiveCitation(undefined);
    setActiveAnalysisPanelTab(undefined);

    try {
      const result = await chatApi(question, sessionId, apiKey);
      setAnswers([...answers, [question, result]]);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const clearChat = () => {
    generateSessionId();
    lastQuestionRef.current = "";
    error && setError(undefined);
    setActiveCitation(undefined);
    setActiveAnalysisPanelTab(undefined);
    setAnswers([]);
  };

  useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

  const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setPromptTemplate(newValue || "");
  };

  const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
    setRetrieveCount(parseInt(newValue || "3"));
  };

  const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    setUseSemanticRanker(!!checked);
  };

  const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    setUseSemanticCaptions(!!checked);
  };

  const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
    setExcludeCategory(newValue || "");
  };

  const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    setUseSuggestFollowupQuestions(!!checked);
  };

  const onExampleClicked = (example: string) => {
    makeApiRequest(example);
  };

  const onShowCitation = (citation: string, index: number) => {
    if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
      setActiveAnalysisPanelTab(undefined);
    } else {
      setActiveCitation(citation);
      setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
    }

    setSelectedAnswer(index);
  };

  const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
    if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
      setActiveAnalysisPanelTab(undefined);
    } else {
      setActiveAnalysisPanelTab(tab);
    }

    setSelectedAnswer(index);
  };

  const generateSessionId = () => {
    const sessionId = uuidv4();
    setSessionId(sessionId);
  };

  useEffect(() => {
    generateSessionId();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.chatRoot}>
          <div className={styles.chatContainer}>
            <div className={styles.commandsContainer}>
            <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
            </div>
            {!lastQuestionRef.current ? (
              <div className={styles.chatEmptyState}>
                <div className={styles.titleContainer}>
                  <p className={styles.titleOne}>Converse</p>
                  <p className={styles.titleTwo}>com a nossa plataforma</p>
                </div>

                <ExampleList onExampleClicked={onExampleClicked} />
              </div>
            ) : (
              <div className={styles.chatMessageStream}>
                {answers.map((answer, index) => (
                  <div key={index}>
                    <UserChatMessage message={answer[0]} />
                    <div className={styles.chatMessageGpt}>
                      <Answer
                        key={index}
                        answer={answer[1]}
                        isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                        onCitationClicked={c => onShowCitation(c, index)}
                        onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                        onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                        onFollowupQuestionClicked={q => makeApiRequest(q)}
                        showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                      />
                    </div>
                  </div>
                ))}
                {isLoading && (
                  <>
                    <UserChatMessage message={lastQuestionRef.current} />
                    <div className={styles.chatMessageGptMinWidth}>
                      <AnswerLoading />
                    </div>
                  </>
                )}
                {error ? (
                  <>
                    <UserChatMessage message={lastQuestionRef.current} />
                    <div className={styles.chatMessageGptMinWidth}>
                      <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                    </div>
                  </>
                ) : null}
                <div ref={chatMessageStreamEnd} />
              </div>
            )}

            <div className={styles.chatInput}>
              <QuestionInput clearOnSend placeholder="Digite uma nova pergunta" disabled={isLoading} onSend={question => makeApiRequest(question)} />
            </div>
          </div>

          {answers.length > 0 && activeAnalysisPanelTab && (
            <AnalysisPanel
              className={styles.chatAnalysisPanel}
              activeCitation={activeCitation}
              onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
              citationHeight="810px"
              answer={answers[selectedAnswer][1]}
              activeTab={activeAnalysisPanelTab}
            />
          )}
          <div className={styles.panel}>
            <Panel
              styles={{
                main: {
                  background: "#2E2E38"
                },
                header: {
                  backgroundColor: "#2E2E38"
                },
                headerText: {
                  color: "white"
                },
                commands: {
                  backgroundColor: "#2E2E38"
                }
              }}
              headerText="Configure suas respostas"
              isOpen={isConfigPanelOpen}
              isBlocking={false}
              hasCloseButton={false}
              onDismiss={() => setIsConfigPanelOpen(false)}
              closeButtonAriaLabel="Close"
              onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
              isFooterAtBottom={true}
            >
              <div className={styles.panel}>
                <TextField
                  className={styles.chatSettingsSeparator}
                  defaultValue={promptTemplate}
                  label="Sobrescrever prompt template"
                  multiline
                  autoAdjustHeight
                  onChange={onPromptTemplateChange}
                />

                <SpinButton
                  className={styles.chatSettingsSeparator}
                  styles={{
                    arrowButtonsContainer: { backgroundColor: "white", color: "red" },
                    icon: { background: "black", color: "black", borderBottomColor: "black" },
                    spinButtonWrapper: { background: "black", color: "black" }
                  }}
                  label="Documentos retornados pela busca:"
                  min={1}
                  max={50}
                  defaultValue={retrieveCount.toString()}
                  onChange={onRetrieveCountChange}
                />
                <TextField className={styles.chatSettingsSeparator} label="Excluir categoria" onChange={onExcludeCategoryChanged} />
                <Checkbox
                  styles={{
                    checkbox: {},
                    input: {},

                    checkmark: {}
                  }}
                  className={styles.chatSettingsSeparator}
                  checked={useSemanticRanker}
                  label="Utilizar rank semântico no retorno"
                  onChange={onUseSemanticRankerChange}
                />
                <Checkbox
                  className={styles.chatSettingsSeparator}
                  checked={useSemanticCaptions}
                  label="Utilize resumos por contexto ao invés de documentos completos"
                  onChange={onUseSemanticCaptionsChange}
                  disabled={!useSemanticRanker}
                />
                <Checkbox
                  className={styles.chatSettingsSeparator}
                  checked={useSuggestFollowupQuestions}
                  label="Sugerir novas perguntas"
                  onChange={onUseSuggestFollowupQuestionsChange}
                />
              </div>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
