import { useRef, useState, useContext } from "react";
import styles from "./Images.module.css";
import { ImageError } from "../../components/ImageAnswer";
import { QuestionInput } from "../../components/QuestionInput";
import { Spinner } from "@fluentui/react";
import { SessionContext } from "../../context/sessionContext";
import { generateImage } from "../../api";

const Images = () => {
    const lastQuestionRef = useRef<string>("");

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [urlImage, setUrlImage] = useState<string>();
    const { apiKey } = useContext(SessionContext);

    const requestGenerateImage = async (message: string) => {
        error && setError(undefined);
        setIsLoading(true);
    
        try {
          const result = await generateImage(message, apiKey);
          setUrlImage(result.url);
        } catch (e) {
          setError(e);
        } finally {
          setIsLoading(false);
        }
    };

    return (
        <div className={styles.oneshotContainer}>
            <div className={styles.titleContainer}>
                <p className={styles.titleOne}>Crie</p>
                <p className={styles.titleTwo}>uma Imagem</p>
            </div>

            <div className={styles.oneshotQuestionInput}>
                <QuestionInput placeholder="Example: Imagem de um cachorro na praia" disabled={isLoading} onSend={question => requestGenerateImage(question)} />
            </div>

            <div className={styles.oneshotBottomSection}>
                {isLoading && <Spinner label="Gerando Resposta" />}
                {!isLoading && urlImage && !error && (
                    <div className={styles.oneshotAnswerContainer}>
                        <img src={urlImage} alt="GeneratedImage" className={styles.imageGenerated} />
                    </div>
                )}
                {error ? (
                    <div className={styles.oneshotAnswerContainer}>
                        <ImageError error={error.toString()} onRetry={() => requestGenerateImage(lastQuestionRef.current)} />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Images;
