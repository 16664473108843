import { Outlet, NavLink, Link } from "react-router-dom";
import ey_logo from "../../assets/ey_logo.png";
import styles from "./Layout.module.css";
import { InputApiKey } from "../../components/InputApiKey";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <div>
                        <ul className={styles.headerNavList}>
                            <li className={styles.headerNavLeftMargin}>
                                <a href="https://www.ey.com/en_gl/ai" target={"_blank"} title="AI Services">
                                    <img src={ey_logo} alt="EY logo" aria-label="Link to EY page" width="36px" height="36px" className={styles.eyLogo} />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <nav className={styles.buttonsContainer}>
                        <NavLink
                            to="/"
                            className={styles.headerTitleContainer}
                            style={({ isActive, isPending }) => {
                                return {
                                    fontWeight: isActive ? "bold" : "",
                                    color: isActive ? "#ffe600" : ""
                                };
                            }}
                        >
                            Converse
                        </NavLink>
                        <NavLink
                            to="qa"
                            className={styles.headerTitleContainer}
                            style={({ isActive, isPending }) => {
                                return {
                                    fontWeight: isActive ? "bold" : "",
                                    color: isActive ? "#ffe600" : ""
                                };
                            }}
                        >
                            Pergunte
                        </NavLink>
                        <NavLink
                            to="image"
                            className={styles.headerTitleContainer}
                            style={({ isActive, isPending }) => {
                                return {
                                    fontWeight: isActive ? "bold" : "",
                                    color: isActive ? "#ffe600" : ""
                                };
                            }}
                        >
                            Imagens
                        </NavLink>
                        <InputApiKey />
                        {/* <NavLink
                            to="pdf"
                            className={styles.headerTitleContainer}
                            style={({ isActive, isPending }) => {
                                return {
                                    fontWeight: isActive ? "bold" : "",
                                    color: isActive ? "#ffe600" : ""
                                };
                            }}
                        >
                            Chat PDF
                        </NavLink> */}
                    </nav>
                </div>
            </header>

            <div className={styles.container}>
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
