import { ChatResponse, GenerateImageResponse, ImageRequest } from "./models";

const baseUrl = "https://beyondlabs-openai-api-python.azurewebsites.net/api";
// const baseUrl = "http://localhost:7071/api";

export async function askApi(question: string, apiKey: string | undefined): Promise<ChatResponse> {
  const response = await fetch(baseUrl + "/qa", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${apiKey}`,
    },
    body: JSON.stringify({
      message: question
    })
  });

  const respJson: ChatResponse = await response.json();

  return respJson;
}

export async function chatApi(question: string, sessionId: string | undefined, apiKey: string | undefined): Promise<ChatResponse> {
  
  const body = {
    message: question,
    project: `bl-chatbot-${sessionId}`
  };

  const response = await fetch(baseUrl + "/chat", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${apiKey}`,
    },
    body: JSON.stringify(body)
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const respJson: ChatResponse = await response.json();

  return respJson;
}

export async function generateImage(question: string, apiKey: string | undefined): Promise<GenerateImageResponse> {
  const body = {
    message: question,
  };

  const response = await fetch(baseUrl + "/generate-image", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${apiKey}`,
    },
    body: JSON.stringify(body)
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const respJson: GenerateImageResponse = await response.json();

  return respJson;
}


export function getCitationFilePath(citation: string): string {
  return `/content/${citation}`;
}
